<template>
  <div class="project-management-page mx-auto">
    <section class="text-3xl text-black mb-20 font-semibold">{{ $t('SEARCH_FOR_PROJECTS') }}</section>
    <div class="search-header mb-20 flex items-center justify-between mx-auto relative">
      <a-input-search
        class="lg:w-7/12 w-full mx-auto"
        v-model="searchText"
        :placeholder="$t('PROJECT_NAME')"
        :enter-button="$t('PROJECT_SEARCH_BTN')"
        @search="handleSearch"
      />
      <div v-if="userRole === 'admin'">
        <router-link
          to="/manage/user-projects/create"
          class="bg-primary hover:opacity-60 text-white font-medium h-8 flex items-center justify-center px-4 rounded-md text-xl"
        >
          {{ $t('ADD_BTN_TEXT') }}
        </router-link>
      </div>
    </div>
    <div
      v-if="userRole === 'admin'"
      class="text-right"
    >
      <button
        @click="() => $router.push('/manage/notice')"
        class="text-base font-medium px-4 py-1 my-2 text-primary rounded-lg bg-transparentBlack mr-4"
      >
        <span>Notice</span>
      </button>
      <button
        @click="() => $router.push('/manage/promote')"
        class="text-base font-medium px-4 py-1 my-2 text-primary rounded-lg bg-transparentBlack mr-4"
      >
        <span>PR Frame</span>
      </button>
      <button
        @click="openCSVModal"
        class="text-base font-medium px-4 py-1 my-2 text-primary rounded-lg bg-transparentBlack"
      >
        <DownloadIcon class="h-6 w-6 inline-block mr-2"></DownloadIcon
        ><span>{{ $t('DOWNLOAD_CSV_SALES') }}</span>
      </button>
    </div>
    <div
      v-if="!loading"
      class="table-wrapper mb-16"
    >
      <ProjectTable :data="projects"></ProjectTable>
    </div>
    <Pagination
      v-if="totalProjects > 0"
      class="text-center mt-8"
      :total="totalProjects"
      :pageSize="limit"
      :current="currentPage"
      @onChange="onChange"
      :showQuickJumper="false"
    ></Pagination>
    <CSVModal
      :visible="csvModalVisible"
      @onCancel="onCSVModalCancel"
    ></CSVModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ProjectTable from '@/components/project-management/ProjectTable';
import Pagination from '@/components/common/Pagination.vue';
import DownloadIcon from '@/components/icons/download.icon.vue';
import CSVModal from '@/components/project-management/CSVModal.vue';
export default {
  name: 'ProjectManagementPage',
  components: {
    CSVModal,
    ProjectTable,
    Pagination,
    DownloadIcon,
  },
  data() {
    return {
      searchText: '',
      data: [],
      // totalProject: 32,
      limit: 10,
      currentPage: 1,
      loading: false,
      csvModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'wallet/loggedUserRole',
      account: 'wallet/account',
    }),
    ...mapState({
      projects: (state) => state.projects.projectsList,
      totalProjects: (state) => state.projects.totalProjects,
    }),
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
  },
  mounted() {
    this.initProjects();
  },
  watch: {
    account() {
      this.initProjects();
    },
  },
  methods: {
    ...mapActions({
      fetchProjects: 'projects/fetchProjects',
    }),
    async initProjects() {
      this.loading = true;
      await this.fetchProjects({
        limit: this.limit,
        offset: this.offset,
        search: this.searchText,
      });
      this.loading = false;
    },
    handleSearch() {
      this.currentPage = 1;
      this.initProjects();
    },
    onChange(page) {
      console.log('page:', page);
      this.currentPage = page;
      this.initProjects();
    },
    onCSVModalCancel() {
      this.csvModalVisible = false;
    },
    openCSVModal() {
      this.csvModalVisible = true;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.table-wrapper {
  @apply bg-purple-bg;
  @apply p-6;
}
::v-deep .ant-input-search {
  .ant-input-group {
    &-addon {
      @apply rounded-r-md;
      @apply bg-primary;
      .ant-btn {
        @apply text-xl bg-primary text-white rounded-r-md border-primary;
      }
    }
    .ant-input {
      background: transparent;
      border: 1px solid white;
      @apply h-9;
      @apply text-white;
      @apply font-medium;
      @apply rounded-none;
      @apply flex items-center;
      background: rgba(16, 16, 16, 0.3);
      @apply text-xl;
      &::placeholder {
        @apply text-white text-xl;
      }
    }
  }
}
</style>
